import logo from './logo.svg';
import './App.scss';

function App() {
  return (
    <div className="App">
      <header className="d-flex align-items-center justify-content-center vh-100">
        <div>
          <img src={logo} className="App-logo" alt="Avanti Marketing Logo" />
          <div class="text-center text-primary">
            Innovate, Grow, Succeed.
          </div>
          <div>
            <div data-netlify-identity-menu></div>
            <div data-netlify-identity-button>Login with Netlify Identity</div>
          </div>
        </div>
      </header>
    </div>
  );
}

export default App;
